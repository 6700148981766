<template>
    <div class="customManage">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['query',{key:'add',text:'新增客户'},'output','goBack']"
                @queryClick="loadData()"  
                @addClick="orderDetail()"
                @outputClick="exportExcel()" 
                @goBackClick="back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">条件检索:</div>
                        <key-board-input class="from-input search"
                            type="text" v-model="searchTxt" placeholder="姓名或手机号糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">客户级别:</div>
                        <div class="from-input">
                            <el-select v-model="cstmLevel">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="item in cstmLevelList"
                                    :key="item.Autoid"
                                    :label="item.LevelName"
                                    :value="item.Autoid"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">营销员:</div>
                        <div class="from-input"><input class="input" v-model="salesName" type="text"/></div>
                    </div>
                </div>
                <div class="table-box">
                    <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                        ref="tableEl"
                        border
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        @cell-click="orderDetail"
                        :data="pageTableData"
                    >
                        <el-table-column prop="Cstm_Name" label="姓名" min-width="100" align="left">
                            <template #default="scope">
                                <span class="link">{{scope.row.Cstm_Name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Cstm_LevelName" label="客户级别" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Cstm_Sex" label="性别" min-width="80">
                            <template #default="scope">{{scope.row.Cstm_Sex?"男":"女"}}</template>
                        </el-table-column>
                        <el-table-column prop="Cstm_Birthday" label="生日" min-width="80" align="left" :formatter="shortDateFormat"></el-table-column>
                        <el-table-column prop="DelayDays" label="年龄" width="50" data-format="number" :formatter="ageFormat"></el-table-column>
                        <el-table-column prop="Cstm_Mobile" label="手机号" min-width="90" align="left" ></el-table-column>
                        <el-table-column prop="Eat_Sales" label="营销员" min-width="70"  align="left" ></el-table-column>
                        <el-table-column prop="Cstm_Company" label="单位名称" min-width="100"  align="left" ></el-table-column>
                        <el-table-column prop="LastTime" label="最近消费日" min-width="125" align="left" :formatter="lastFormat"></el-table-column>
                        <el-table-column prop="TotalPreNum" label="预定次数" min-width="70" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="IDCardNO" label="身份证号" min-width="120" align="left"></el-table-column> 
                        <el-table-column prop="CREATER" label="创建人" min-width="100" align="left"></el-table-column> 
                        <el-table-column prop="CREATE_TIME" label="创建时间" width="130" align="left" :formatter="dateFormat"></el-table-column> 
                        <el-table-column prop="LASTUPDATED_USER" label="最后更新人" min-width="100" align="left"></el-table-column> 
                        <el-table-column prop="LASTUPDATED_TIME" label="最后更新时间" width="130" align="left" :formatter="dateFormat"></el-table-column> 
                        <el-table-column prop="REMARK" label="备注" min-width="100" align="left"></el-table-column> 
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="filterData" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>pageTableData=list" 
                    :pageSizes="[20,30,50]"
                    :page-size="pageSize"
                    @sizeChange="(size)=>pageSize=size">
                </table-page-btn>
            </div>
        </div>
    </div>
</template>

<script>
/**客户管理 */
export default {
    name:"customManage",
    data(){
        let pageSize= this.$cacheData.get("customManage_pageSize")||20;
        return {
            currentPage:1,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //客户级别
            cstmLevelList:[],
            //客户级别id
            cstmLevel:"",
            //营销员
            salesName:"",
            /**账单信息 */
            tableData:[],
            pageTableData:[]
        }
    },
    computed:{
        /**筛选 */
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Cstm_Mobile||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Cstm_Name||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            if(this.cstmLevel){
                data=data.filter(it=>it.Cstm_LevelID==this.cstmLevel);
            }
            let salesName=(this.salesName||"").trim();
            if(salesName){
                salesName=salesName.toLowerCase();
                data=data.filter(it=>(it.Eat_Sales||"").toLowerCase().indexOf(salesName)>=0);
            }
            return data;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("customManage_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        
        this.$cacheData.CstmLevel().then(d=>{
            this.cstmLevelList=d;
            console.log(d)
        }).catch(err=>{
            console.log("客户级别获取失败",err)
        })
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        Promise.all([this.loadData(false)]).then(()=>{
            loading.close();
        }).catch(err=>{
            console.log(err) // 失败 
        })
    },
    methods:{
        back(){
            this.$router.push('cloudPosPre');
        },
        /**查询数据 */
        loadData(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetCstmList", {}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.tableData=d.ResponseBody;
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /**年龄 */
        ageFormat(row, column, cellValue){
            if (row.Cstm_Birthday) {
                let birthday=new Date(row.Cstm_Birthday);
                let birthYear = birthday.getFullYear();
                let birthMonth = birthday.getMonth() + 1;
                let birthDay = birthday.getDate();
                let dayTime=new Date();
                let todayYear = dayTime.getFullYear();
                let todayMonth = dayTime.getMonth() + 1;
                let todayDay = dayTime.getDate();

                let ageYear = todayYear - birthYear;
                let ageMonth = todayMonth - birthMonth;
                let ageDay = todayDay - birthDay;

                if (ageMonth < 0 || (ageMonth === 0 && ageDay < 0)) {
                    ageYear--;
                }
                return ageYear;
            }
            else {
                return "";
            }
        },
        /**最近消费日 */
        lastFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            var strDate = (new Date(cellValue)).Format('yyyy-MM-dd');
            var today = new Date(new Date().Format('yyyy-MM-dd')).getTime();
            var doDay = new Date(strDate).getTime();
            var daynum = (today - doDay) / (1000 * 60 * 60 * 24);
            if (daynum > 3) {
                if (daynum > 7) {
                    if (daynum > 15) {
                        if (daynum > 30) {
                            if (daynum > 90) {
                                return "三月前(" + strDate + ")";
                            }
                            return "一月前(" + strDate + ")";
                        }
                        return "15天前(" + strDate + ")";
                    }
                    return "7天前(" + strDate + ")";
                }
                return "3天前(" + strDate + ")";
            }

            return strDate;
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**导出excel */
        exportExcel(){
            // if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
            //     this.$message.warning('您没有导出报表权限，请先分配权限！');
            //     return;
            // }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"预订客户列表",
                list:this.filterData
            })
        },
        //穿透
        orderDetail(row, column){
            if(column?.property=="Cstm_Name"){//修改
                this.$router.push({name: 'customManageEdit', params:{id:row.CstmAutoID}}); 
            }else if(!row){//新增
                this.$router.push({name: 'customManageEdit'}); 
            }
        }
    }
}
</script>

<style lang="scss">
    @import './customManage.scss'
</style>